import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import logo from "../../../assets/images/blog/sams/Sams_logo.png"
import tablet from "../../../assets/images/blog/sams/tablet1.png"
import tablet1 from "../../../assets/images/blog/sams/tablet1-Kopiev2.png"
import tablet2 from "../../../assets/images/blog/sams/tablet_and_screens_2.png"
import tablet3 from "../../../assets/images/blog/sams/chart.png"
import tablet4 from "../../../assets/images/blog/sams/tablet_and_screen.png"


export default () => 
<div>
    <SEO title={'SAMS: Medical App Development - Case Study | ICON Worldwide'} 
    description="SAMS, a revolutionary medical app system that makes it possible for physicians to perform remote clinical eye examinations: discover more on our blog!"
    canonical={'https://icon-worldwide.com/blog/sams-app-development-case-study'} />

    <NewsHeader/>

    <div id="single-news">
        <h1><span>SAMS:</span> Medical App Development [Case Study]</h1>
        <div id="title-underline"></div>
        <h2>Introducing SAMS: Mobile Medical Training & Education App</h2>
        <img src={logo} style={{margin:'20px 0'}} alt="ICON Worldwide logo" title="ICON Worldwide logo"/>
        <div id="single-news-texts">
            <p>We developed the SAMS, Saccades Analysis Made Simple, <a href="https://www.designrush.com/agency/wearable-app-developers" target="_blank" rel="noopener noreferrer">application</a> as part of a clinical study conducted by
            medical expert centers. The SAMS system makes it possible for physicians to perform remote clinical eye examinations.
            The system is designed to educate and train physicians to better recognize eye movement signs related to certain rare diseases.</p>
            <p>The eye examination is conducted via an android app on a Samsung Galaxy View tablet. The video file of the exam is then saved in the
            cloud where a remote expert can view the exam and provide feedback.</p>
            <p>This project utilized the full ICON in-house capabilities including project consulting, branding, user interface design, Android and web development.</p>
            <p>ICON hosts and supports the ongoing operations of the project from its Zurich base providing customer support on demand.</p>
        </div>
            <img className="two-images" src={tablet} alt="medical app development, ICON Worldwide Blog, sams project" title="ICON Worldwide blog, medical app development, sams project"/>
            <img className="two-images right" src={tablet1} aalt="medical app development, ICON Worldwide Blog, sams project" title="ICON Worldwide blog, medical app development, sams project"/>

        <div id="single-news-texts">
            <p>Nieman Pick Type-C is an extremely rare disease for which one of the indicators can be detected during a standard eye exam. As with all rare diseases,
            the number of physicians with enough experience to diagnose this illness is limited. The SAMS app stores patient exams in the secure cloud where they can
            be accessed and reviewed by qualified experts in this rare disease. The expert feedback is then made available to the original physician on the SAMS tablet.</p>
        </div>
        <h2 class="bigger-space">The Eye Exams</h2>
        <div id="single-news-texts">
            <p>The eye exam is comprised of five individual exams, which can be ordered via a dashboard into a custom video examination.</p>
            <p></p>
        </div>
        <img src={tablet2} className="two-images" alt="medical app development, ICON Worldwide Blog, sams project" title="ICON Worldwide blog, medical app development, sams project"/>

        <div id="single-news-texts">
            <p>The review of the exams is done via the portal website where doctors make notes, request second opinions, and eventually submit their feedback to the submitting physician.</p>
            <p>The SAMS app is not available to the general public, and is currently in use in several countries.</p>
        </div>

        <h2 class="bigger-space">The Hardware</h2>
        <div id="single-news-texts">
            <p>The SAMS app is an Android app designed specifically for the Samsung Glaxy View tablet. Originally conceived as an iPad Pro application, it was quickly determined that the camera placement
            on the iPad made capturing a head-on, wide view impossible. The Samsung Galaxy View, with an 18.4“ wide display, is the largest tablet available.</p>
        </div>
        <img className="two-images" src={tablet3} alt="medical app development, ICON Worldwide Blog, sams project" title="ICON Worldwide blog, medical app development, sams project"/>
        <img className="two-images right" src={tablet4} alt="medical app development, ICON Worldwide Blog, sams project" title="ICON Worldwide blog, medical app development, sams project"/>

        <h2 class="bigger-space">The technical specifications</h2>
        <div id="single-news-texts">
            <p>The clinical environment of major hospitals created several technical hurdles which had to be considered. Hospitals are highly secure IT environments which means the app had to be developed
            to work entirely offline and in examination rooms without internet access. This impacts several normal processess such as account creation, but also the ability to sync extremely large video 
            files to the cloud. ICON Worldwide has been recognized as a <a  href="https://www.softwaredevelopmentcompany.co/healthcare-software-development-companies/" targrt="_blank" >Best Medical Software Development Company.</a></p>
        </div>
        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

        <NewsFooter previous="applying-ai-technologies-to-rare-disease-diagnosis" next="zielabi-app-development-case-study"/>
    </div>

    <Footer noScrollbar="true"/>
</div>